import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusAboutUsTab, AboutUsTabEdge } from './about-us.interface';

export default (): AboutUsTabEdge[] => {
  const { allDirectusAboutUsTab } = useStaticQuery<AllDirectusAboutUsTab>(graphql`
    query {
      allDirectusAboutUsTab(sort: { order: ASC, fields: [directusId, content___sort] }) {
        edges {
          node {
            directusId
            title
            title_in_tab
            slug
            content {
              slug
              title
              content
              image {
                data {
                  full_url
                }
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              width
              configuration
            }
          }
        }
      }
    }
  `);

  return allDirectusAboutUsTab.edges;
};
