// extracted by mini-css-extract-plugin
export var magicNumber__body = "MagicNumbers--magicNumber__body--CvdXF";
export var magicNumber__content = "MagicNumbers--magicNumber__content--qkmXy";
export var magicNumber__divider = "MagicNumbers--magicNumber__divider--asaMH";
export var magicNumber__divider___businessBanking = "MagicNumbers--magicNumber__divider___businessBanking--PoCB8";
export var magicNumber__divider___companiesFormed = "MagicNumbers--magicNumber__divider___companiesFormed--Mo3Vl";
export var magicNumber__divider___customerReviews = "MagicNumbers--magicNumber__divider___customerReviews--dinwP";
export var magicNumber__divider___formationTime = "MagicNumbers--magicNumber__divider___formationTime--WefN7";
export var magicNumber__divider___locations = "MagicNumbers--magicNumber__divider___locations--7S5fh";
export var magicNumber__divider___responseTime = "MagicNumbers--magicNumber__divider___responseTime--Z4sH3";
export var magicNumber__divider___scholarships = "MagicNumbers--magicNumber__divider___scholarships--RZZfc";
export var magicNumber__divider___staffAge = "MagicNumbers--magicNumber__divider___staffAge--SuEmM";
export var magicNumber__divider___topRated = "MagicNumbers--magicNumber__divider___topRated--zjgJV";
export var magicNumber__dividers = "MagicNumbers--magicNumber__dividers--Q6bV5";
export var magicNumber__heading = "MagicNumbers--magicNumber__heading--TxRQX";
export var magicNumber__icon = "MagicNumbers--magicNumber__icon--cE57P";
export var magicNumber__item = "MagicNumbers--magicNumber__item--fuvYl";
export var magicNumber__item___businessBanking = "MagicNumbers--magicNumber__item___businessBanking--XWqjW";
export var magicNumber__item___companiesFormed = "MagicNumbers--magicNumber__item___companiesFormed--OiLIt";
export var magicNumber__item___customerReviews = "MagicNumbers--magicNumber__item___customerReviews--6AlO-";
export var magicNumber__item___formationTime = "MagicNumbers--magicNumber__item___formationTime--EWi5g";
export var magicNumber__item___locations = "MagicNumbers--magicNumber__item___locations---1gbG";
export var magicNumber__item___responseTime = "MagicNumbers--magicNumber__item___responseTime--o5zA5";
export var magicNumber__item___scholarships = "MagicNumbers--magicNumber__item___scholarships--pT-vt";
export var magicNumber__item___staffAge = "MagicNumbers--magicNumber__item___staffAge--hn0iz";
export var magicNumber__item___topRated = "MagicNumbers--magicNumber__item___topRated--4bQVi";
export var magicNumber__items = "MagicNumbers--magicNumber__items--Lj4xK";
export var magicNumber__number = "MagicNumbers--magicNumber__number--5vvMj";
export var magicNumber__title = "MagicNumbers--magicNumber__title--Pi+G5";
export var tkMyriadProSemiCondensed = "MagicNumbers--tk-myriad-pro-semi-condensed--OAKRS";