import React from 'react';
import slugify from 'react-slugify';

import staffReviewsHook from 'hooks/staff-reviews/staff-reviews.hook';

import { title, quotes, quote, quote__body, quote__footer, quote__footer__cite } from './StaffReviews.scss';

/* tslint:disable */
const IMAGE_URL =
  'https://0a2c847a4a880281385a-3120cb5c7e2c24513a9845ad2e9130a6.ssl.cf3.rackcdn.com/images/system/2015/banner-quote.png';

export default (): JSX.Element => {
  const reviews = staffReviewsHook();

  return (
    <>
      <h2 className={title}>What our people have to say</h2>

      <div className={quotes}>
        {reviews.map(({ node }) => (
          <blockquote
            key={`review-${slugify(node.staff.full_name)}`}
            className={quote}
            style={{ backgroundImage: `url(${IMAGE_URL})` }}
          >
            <p className={quote__body}>{node.content}</p>

            <footer className={quote__footer}>
              {node.staff.full_name}
              <cite className={quote__footer__cite}>{node.staff.job_title}</cite>
            </footer>
          </blockquote>
        ))}
      </div>
    </>
  );
};
