import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Markdown from 'components/common/markdown/Markdown';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import magicNumbersHook from 'hooks/magic-numbers/magic-numbers.hook';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { getSolidFontAwesomeIcon } from 'utils/font-awesome/solid';

import {
  magicNumber__items,
  magicNumber__item,
  magicNumber__icon,
  magicNumber__content,
  magicNumber__heading,
  magicNumber__body,
  magicNumber__dividers,
  magicNumber__divider,
  magicNumber__item___customerReviews,
  magicNumber__divider___customerReviews,
  magicNumber__item___locations,
  magicNumber__divider___locations,
  magicNumber__item___companiesFormed,
  magicNumber__divider___companiesFormed,
  magicNumber__item___scholarships,
  magicNumber__divider___scholarships,
  magicNumber__item___staffAge,
  magicNumber__divider___staffAge,
  magicNumber__item___formationTime,
  magicNumber__divider___formationTime,
  magicNumber__item___responseTime,
  magicNumber__divider___responseTime,
  magicNumber__item___businessBanking,
  magicNumber__divider___businessBanking,
  magicNumber__item___topRated,
  magicNumber__divider___topRated,
} from './MagicNumbers.scss';

const getDividerClass = (slug: string) => {
  switch (slug) {
    case 'customer-reviews':
      return magicNumber__divider___customerReviews;
    case 'locations':
      return magicNumber__divider___locations;
    case 'companies-formed':
      return magicNumber__divider___companiesFormed;
    case 'scholarships':
      return magicNumber__divider___scholarships;
    case 'staff-age':
      return magicNumber__divider___staffAge;
    case 'formation-time':
      return magicNumber__divider___formationTime;
    case 'response-time':
      return magicNumber__divider___responseTime;
    case 'business-banking':
      return magicNumber__divider___businessBanking;
    case 'top-rated':
      return magicNumber__divider___topRated;
    default:
      return '';
  }
};

const getItemClass = (slug: string) => {
  switch (slug) {
    case 'customer-reviews':
      return magicNumber__item___customerReviews;
    case 'locations':
      return magicNumber__item___locations;
    case 'companies-formed':
      return magicNumber__item___companiesFormed;
    case 'scholarships':
      return magicNumber__item___scholarships;
    case 'staff-age':
      return magicNumber__item___staffAge;
    case 'formation-time':
      return magicNumber__item___formationTime;
    case 'response-time':
      return magicNumber__item___responseTime;
    case 'business-banking':
      return magicNumber__item___businessBanking;
    case 'top-rated':
      return magicNumber__item___topRated;
    default:
      return '';
  }
};

export default (): JSX.Element => {
  const numbers = magicNumbersHook();
  const { node } = getFieldBySlug('magic-numbers', sectionTitleHook());

  return (
    <>
      <SectionTitle title={node.heading} align="center" />

      <div className={magicNumber__items}>
        {numbers.map(({ node }) => (
          <div key={`magic-number-${node.slug}`} className={`${magicNumber__item} ${getItemClass(node.slug)}`}>
            <div className={magicNumber__icon} style={{ backgroundColor: node.colour }}>
              <FontAwesomeIcon icon={getSolidFontAwesomeIcon(node.icon)} />
            </div>

            <div className={magicNumber__content}>
              <h4 className={magicNumber__heading}>
                <Markdown source={node.heading} />
              </h4>
              <Markdown className={magicNumber__body} source={node.subheading} />
            </div>
          </div>
        ))}
        <div className={magicNumber__dividers}>
          {numbers.map(({ node }) => (
            <div
              key={`magic-number-divider-${node.directusId}`}
              className={`${magicNumber__divider} ${getDividerClass(node.slug)}`}
              style={{ backgroundColor: node.colour }}
            />
          ))}
        </div>
      </div>
    </>
  );
};
