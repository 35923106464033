import { graphql, useStaticQuery } from 'gatsby';

import { AllDirectusStaffTestimonial, StaffTestimonialEdges } from './staff-reviews.interface';

export default (): StaffTestimonialEdges[] => {
  const { allDirectusStaffTestimonial } = useStaticQuery<AllDirectusStaffTestimonial>(graphql`
    query {
      allDirectusStaffTestimonial(sort: { fields: sort, order: ASC }) {
        edges {
          node {
            directusId
            staff {
              full_name
              job_title
            }
            content
          }
        }
      }
    }
  `);

  return allDirectusStaffTestimonial.edges;
};
