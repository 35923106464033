import React from 'react';
import slugify from 'react-slugify';

import Modal from 'components/common/modal/Modal';
import { Props } from './MemberCard.interface';

import {
  memberCard,
  memberCard__image,
  memberCard__body,
  memberCard__staffName,
  memberCard__jobTitle,
  tooltip__imageBlock,
  tooltip__imageBlock__image,
  tooltip__content,
  tooltip__title,
  tooltip__list,
} from './MemberCard.scss';

export default ({ staff }: Props): JSX.Element => {
  const department = slugify(staff.department.name);

  return (
    <div className={memberCard}>
      <Modal
        trigger={
          <>
            <div className={memberCard__image}>
              <img alt={`Profile of ${staff.full_name}`} src={staff.profile_photo.data.full_url} />
            </div>
            <div className={`${memberCard__body} ${memberCard__body}__${department}`}>
              <h3 className={memberCard__staffName}>{staff.full_name}</h3>
              <p className={memberCard__jobTitle}>{staff.job_title}</p>
            </div>
          </>
        }
      >
        <>
          <div className={tooltip__imageBlock}>
            <img
              alt={`Profile of ${staff.full_name}`}
              src={staff.profile_photo.data.full_url}
              className={tooltip__imageBlock__image}
            />
          </div>
          <div className={tooltip__content}>
            <h3 className={tooltip__title}>{staff.full_name}</h3>
            <span>{staff.job_title}</span>
            <ul className={tooltip__list}>
              <li>
                <b>Favourite Drink</b>: {staff.favourite_drink}
              </li>
              <li>
                <b>Favourite TV Series</b>: {staff.favourite_tv_series}
              </li>
              <li>
                <b>Three Things You Can&apos;t Live Without</b>: {staff.favourite_three_things}
              </li>
            </ul>
          </div>
        </>
      </Modal>
    </div>
  );
};
