import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import mapboxgl, { Map } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import Markdown from '../markdown/Markdown';
import { marker as markerClass } from './Map.scss';
import { MapProps, Props } from './Map.interface';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MapPopUpComponent = ({ title, description }: { title: string; description: string }) => (
  <>
    <h3>{title}</h3>
    <p>
      <Markdown source={description} />
    </p>
  </>
);

export default ({ mapProps, markerProps }: Props): JSX.Element => {
  const mapEl = useRef<HTMLElement>(null) as React.MutableRefObject<HTMLDivElement>;
  const [map, setMap] = useState<Map>(null);
  const [mounted, setMounted] = useState<boolean>(false);
  const mapboxToken = process.env.GATSBY_MAPBOX_ACCESS_TOKEN;
  const maptilerToken = process.env.GATSBY_MAPTILER_ACCESS_KEY;
  const { controls, center, zoom, mapHeight = '30rem' } = mapProps;
  const { lngLat, markerPopup } = markerProps;

  useEffect(() => {
    mapboxgl.accessToken = mapboxToken;

    const initializeMap = ({ setMap, mapEl }: MapProps) => {
      setMounted(true);
      const map = new mapboxgl.Map({
        center,
        zoom,
        container: mapEl.current,
        style: `https://api.maptiler.com/maps/streets/style.json?key=${maptilerToken}`,
      });
      map.on('load', () => {
        if (mounted) {
          setMap(map);
          map.resize();
        }
      });

      if (controls) {
        const mapControls = new mapboxgl.NavigationControl({
          showCompass: controls.compass,
          showZoom: controls.zoom,
          visualizePitch: controls.pitch,
        });
        map.addControl(mapControls, controls.position);
      }

      const markerEl = document.createElement('div');
      const popUpEl = document.createElement('div');
      markerEl.className = markerClass;
      const marker = new mapboxgl.Marker(markerEl).setLngLat(lngLat);

      if (markerPopup) {
        ReactDOM.render(
          <MapPopUpComponent title={markerPopup.markerTitle} description={markerPopup.markerDescription} />,
          popUpEl
        );

        marker.setPopup(new mapboxgl.Popup({ offset: 25 }).setDOMContent(popUpEl));
      }
      marker.addTo(map);
    };
    if (!map) initializeMap({ setMap, mapEl });
    return () => setMounted(false);
  }, []);

  if (typeof window === 'undefined') return <></>;

  return <div id="map" style={{ height: mapHeight }} ref={mapEl} />;
};
