// extracted by mini-css-extract-plugin
export var memberCard = "MemberCard--memberCard----M-x";
export var memberCard__body = "MemberCard--memberCard__body--HhDLW";
export var memberCard__image = "MemberCard--memberCard__image--EiiAQ";
export var memberCard__jobTitle = "MemberCard--memberCard__jobTitle--ZeLI6";
export var memberCard__staffName = "MemberCard--memberCard__staffName--MnaAb";
export var memberCards = "MemberCard--memberCards--fXXWq";
export var tkMyriadProSemiCondensed = "MemberCard--tk-myriad-pro-semi-condensed--2CoeM";
export var tooltip = "MemberCard--tooltip--Vh6rF";
export var tooltipContainer = "MemberCard--tooltipContainer--nK7Zo";
export var tooltipWidget = "MemberCard--tooltipWidget--vaV16";
export var tooltip__content = "MemberCard--tooltip__content--jWyCU";
export var tooltip__imageBlock = "MemberCard--tooltip__imageBlock--f8VRc";
export var tooltip__imageBlock__image = "MemberCard--tooltip__imageBlock__image--n+Hzh";
export var tooltip__list = "MemberCard--tooltip__list--Cv4jj";
export var tooltip__title = "MemberCard--tooltip__title--NTuDp";
export var tooltip__wrapper = "MemberCard--tooltip__wrapper--3MAQk";