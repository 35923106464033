// extracted by mini-css-extract-plugin
export var aboutUsContent = "AboutUsContent--aboutUsContent--v1cXv";
export var aboutUsContent___aboutUs = "AboutUsContent--aboutUsContent___aboutUs--DENvn";
export var aboutUsContent___joinOurTeam = "AboutUsContent--aboutUsContent___joinOurTeam--AS6dp";
export var aboutUsContent___ourServices = "AboutUsContent--aboutUsContent___ourServices--YcDHG";
export var aboutUsContent___ourStory = "AboutUsContent--aboutUsContent___ourStory--zHjfC";
export var aboutUsContent___ourValues = "AboutUsContent--aboutUsContent___ourValues--hsnsH";
export var aboutUsContent__image = "AboutUsContent--aboutUsContent__image--lHOcm";
export var aboutUsContent__item = "AboutUsContent--aboutUsContent__item--c00rC";
export var aboutUsContent__item___full = "AboutUsContent--aboutUsContent__item___full--+6UM4";
export var aboutUsContent__item___grey = "AboutUsContent--aboutUsContent__item___grey---z1rZ";
export var aboutUsContent__item___left = "AboutUsContent--aboutUsContent__item___left--aY67y";
export var aboutUsContent__item___right = "AboutUsContent--aboutUsContent__item___right--XyhFt";
export var aboutUsContent__title = "AboutUsContent--aboutUsContent__title--ad+SE";
export var tkMyriadProSemiCondensed = "AboutUsContent--tk-myriad-pro-semi-condensed--P7PlO";