import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import Img from 'gatsby-image';

import Markdown from 'components/common/markdown/Markdown';
import aboutUsHook from 'hooks/about-us-tab/about-us.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { Props } from './AboutUsContent.interface';

import {
  aboutUsContent,
  aboutUsContent__item___grey,
  aboutUsContent__item,
  aboutUsContent__item___full,
  aboutUsContent__item___left,
  aboutUsContent__item___right,
  aboutUsContent__title,
  aboutUsContent__image,
  aboutUsContent___aboutUs,
  aboutUsContent___ourStory,
  aboutUsContent___ourValues,
  aboutUsContent___ourServices,
  aboutUsContent___joinOurTeam,
} from './AboutUsContent.scss';

const getContentClass = (slug: string): string => {
  switch (slug.toLowerCase()) {
    case 'about-us':
      return aboutUsContent___aboutUs;
    case 'our-story':
      return aboutUsContent___ourStory;
    case 'our-values':
      return aboutUsContent___ourValues;
    case 'our-services':
      return aboutUsContent___ourServices;
    case 'join-our-team':
      return aboutUsContent___joinOurTeam;
    default:
      return '';
  }
};

const getContentWidth = (width: string): string => {
  switch (width.toLowerCase()) {
    case 'full':
      return aboutUsContent__item___full;
    case 'left':
      return aboutUsContent__item___left;
    case 'right':
      return aboutUsContent__item___right;
    default:
      return '';
  }
};
export default ({ slug }: Props): JSX.Element => {
  const { node } = getFieldBySlug(slug, aboutUsHook());
  if (slug === 'about-us') {
    return (
      <div className={`${aboutUsContent} ${getContentClass(slug)}`}>
        {node.content.map(
          ({ slug, title, content, image, width }): JSX.Element => (
            <div className={`${aboutUsContent__item} ${getContentWidth(width)}`} key={slug}>
              {image && (
                <Img
                  fluid={image.localFile.childImageSharp.fluid}
                  alt={title}
                  className={aboutUsContent__image}
                  fadeIn
                />
              )}
              <div>
                <Markdown className={aboutUsContent__title} source={title} container />
                <Markdown source={content} container />
              </div>
            </div>
          )
        )}
      </div>
    );
  }

  return (
    <div className={`${aboutUsContent} ${getContentClass(slug)}`}>
      {node.content.map(({ slug, title, content, image, width, configuration }): JSX.Element => {
        const styles = {
          width: getContentWidth(width),
          grey: configuration ? aboutUsContent__item___grey : '',
        };

        return (
          <div key={slug} className={`${aboutUsContent__item} ${styles.width} ${styles.grey}`}>
            {title && <Markdown className={aboutUsContent__title} source={title} container />}
            {image && (
              <Img fluid={image.localFile.childImageSharp.fluid} alt={title} className={aboutUsContent__image} fadeIn />
            )}
            <Markdown
              container
              source={content}
              listItemIcon={<FontAwesomeIcon icon={faCheck} size="sm" aria-label="check icon." />}
            />
          </div>
        );
      })}
    </div>
  );
};
